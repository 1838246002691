<template>
  <div>
    <div class="contentFrame">
      <div class="leftSide">
        <div class="subinfoimg">
          <img src="../../assets/images/meetsanta1.jpg" alt="" />
        </div>
<h3>サンタクロースとは</h3>
        <div class="sentent_r1">
            サンタクロースは、ヨーロッパの北部（北欧）のフィンランドの旧ラップランド地方のコルバトゥントゥリ（コルヴァトゥントゥリ）という山に住んでいます。 お手伝いをしてくれる妖精のトントゥー達と一緒に暮らしています。
コルヴァトゥントゥリという山の名前の由来は、フィンランド語で「大きな耳」と言う意味があります。 サンタクロースは、世界中の子供たちの、どんな小さな声でも、ここから聞くことが出来るのです。

        </div>

<div class="section">
        <h3>サンタクロースが子供たちに会いにくる</h3>

  <div class="centeimg2"><img src="../../assets/images/meetsanta2.jpg" alt=""></div>

<div class="sentent">フィンランド･旧ラップランド州公認のサンタクロースが、日本の子供たちに会いに来てくれます。 子どもたちはサンタクロースに会うと、本当に目を輝かせて喜びます。
少しでもたくさんの子供たちにあえるように、地域のデパートやショッピングセンター、ホテル等の大きな施設で、サンタクロースと子供たちとの楽しい時間を演出いたします。
当日は、サンタクロースとの写真撮影会や、サンタクロースへの質問にもお答えいたします。
</div>
</div>


<div class="section">
        <h3>サンタクロースの訪問をご希望の方へ</h3>

  <div class="centeimg1">
      <img src="../../assets/images/meetsanta3.gif" alt="">
      <img src="../../assets/images/meetsanta4.jpg" alt="">
      <img src="../../assets/images/meetsanta5.jpg" alt="">
      <img src="../../assets/images/meetsanta6.jpg" alt="">
  </div>

<div class="sentent">サンタクロース大使館では１５年間に渡り、フィンランド、旧ラップランド州公認のサンタクロース日本訪問の窓口となっております。 私たちサンタクロース大使館は、サンタクロースからの手紙やサンタクロース日本公式訪問のお手伝いをしています。
大人が子供たちの目の高さで、物事を考え、感じ取り、２１世紀を担う子供たちに愛と勇気そして屈託のない素晴らしい笑顔の絶えないそんな時間をサンタクロースと共に、お伝え出来ればと考えております。 たくさんの地域で、サンタクロースを待っている子供たちがいます。 地域の皆様のご協力を賜り、一人でも多くの子供たちにサンタクロースとのふれあいの 時間を提供出来ればと思っています。
サンタクロース訪問に伴う、イベント企画等ございましたらご連絡下さい。

<br><div class="address">
サンタクロース大使館<br>
〒325-0303<br>
栃木県那須郡那須町高久乙3584-4<br>
TEL 0287-74-6503<br>
FAX 0287-74-6506


  <div class="centeimg3">
            <router-link @click.native="scrollToTop" class="bn" to="/informations/qa"><img src="../../assets/images/bn_contact.png" alt="" /></router-link>

  </div>
</div>
</div>
</div>



      </div>
      <div class="rightSide">
        <sideContents />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$parent.middle = 2;
  },
};
</script>

<style scoped lang="scss">
$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}

@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
.leftSide{
    img{
        max-width: 100%;
    }
}
.address{
  border: solid 1px #ccc;
  border-radius: 1em;
  margin: 1em 0;
  padding: 1em;
  h3{
    font-size: 2em;
  }
}
.subinfotext {
  color: #454552;
  font-size: 1.5em;
  font-weight: bold;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets/images/ilst1.png") no-repeat left center /
    contain;
  @include sp {
    padding-left: 10vw;
    font-size: 1em;
  }
}
.sentent_r1 {
  color: #d72e23;
  line-height: 1.7;
  margin: 1em 0;
}
.sentent {
  text-align: left;
  color: #454552;
  line-height: 1.7;
  margin: 1em 0;
}
  h3 {
    padding: 2em 0;
  }
  h4 {
    margin: 1em 0;
    padding-left: 1em;
    border-left: double 10px #d72e23;
  }
.centeimg1 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 50%;
    max-width: 100%;
    @include sp{
    width: 50%;
    }
  }
}
.centeimg2 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 90%;
    max-width: 100%;
  }
}
.centeimg3 {
  padding: 1em 0;
  text-align: center;
  img {
    width: 60%;
    max-width: 100%;
    @include sp{
    width: 100%;
    }
  }
}
</style>